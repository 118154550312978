<template>
  <div v-if="isLoading || isLoadingVendor" class="w-100 h-100 d-flex align-items-center justify-content-center">
    <loading-content />
  </div>

  <div v-else class="vendor-edit container">
    <b-row>
      <b-col cols="12" class="d-flex align-items-center">
        <div role="button" @click="discardAndBack">
          <feather type="arrow-left" class="mr-3" />

          <span class="sr-only">Back</span>
        </div>

        <h2 class="m-0">{{ pageTitle }}</h2>
      </b-col>
    </b-row>

    <b-form class="mt-4" id="vendorForm" @submit.prevent="handleSubmit" @reset.prevent="handleReset">
      <b-tabs content-class="m-3">
        <b-tab title="General Configs">
          <b-row>
            <b-col cols="12">
              <h4>General configs</h4>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Vendor name" label-for="vendor-name" class="vendor-edit__form-group-name"
                :state="isValid('vendor.name')" :invalid-feedback="handleError('vendor.name')">
                <b-form-input id="vendor-name" v-model="vendor.name"></b-form-input>
              </b-form-group>
            </b-col>

            <div class="pl-1">
              <b-form-group label="Is Partner?" :state="isValid('vendor.is_partner')"
                :invalid-feedback="handleError('vendor.is_partner')">
                <template #label>
                  <div>
                    <div aria-describedby="partner-describe">Is Partner?</div>
                    <small id="partner-describe" class="text-muted">
                      A Sword Partner is a vendor that Sword has a contract with and has clients coming through it
                    </small>
                  </div>
                </template>

                <template #default="{ ariaDescribedby }">
                  <b-form-radio v-model="vendor.is_partner" :value="true" :aria-describedBy="ariaDescribedby"
                    name="is-partner">
                    Yes
                  </b-form-radio>

                  <b-form-radio v-model="vendor.is_partner" :value="false" :aria-describedBy="ariaDescribedby"
                    name="is-partner">
                    No
                  </b-form-radio>
                </template>
              </b-form-group>
            </div>
          </b-row>

          <b-row class="mt-3">
            <b-col cols="12">
              <h4>Vendor Services Configs</h4>
            </b-col>

            <b-form-group v-if="Array.isArray(services) && services.length" v-slot="{ ariaDescribedby }"
              label="Vendor Services Configs" label-sr-only>
              <b-form-checkbox v-for="(service, index) in services" :key="`service-${index}`" :id="`service-${index}`"
                :ref="`serviceCheckbox-${index}`" v-model="vendor.services" class="my-1" name="vendor-service-configs"
                size="lg" :value="service" :aria-describedBy="ariaDescribedby" switch>
                <div class="d-flex align-items-center">
                  <div v-b-tooltip.hover class="cursor-pointer" :style="{ fontSize: '1rem !important' }"
                    :title="getServiceTooltip(service.id)">
                    {{ service.name }}
                  </div>

                  <b-button class="ml-2" variant="link" size="sm" :disabled="!isChecked(service.id)"
                    @click.prevent.stop="editServiceDetails(service)">
                    <feather type="edit"></feather>
                    Edit
                  </b-button>
                </div>
              </b-form-checkbox>
            </b-form-group>

            <div v-else>
              No vendors services added, add one on the Care Ecosystem > Vendors services page
            </div>
          </b-row>
        </b-tab>

        <b-tab v-if="isEditing" :title="`Active Clients  (${pagination.totalRows})`">
          <b-row>
            <b-col cols="12">
              <h4>Active Clients Associated Configs</h4>
            </b-col>
            <b-col cols="12">
              <div v-if="pagination.totalRows > 0">
                <div v-if="isLoadingClients">
                  <loading-content label="Loading clients info" />
                </div>
                <div v-else>
                  <b-table striped hover :items="vendorClients" :fields="tableFields">
                    <template #cell(actions)="row">
                      <b-button
                      v-b-tooltip.hover
                      title="Edit"
                      variant="light"
                      size="sm"
                      :to="'/onboarding/client/edit/' + row.item.id + '?activeTab=EcosystemVendors&vendorId=' + vendor.id">
                        <feather type="edit-2"></feather>
                      </b-button>
                    </template>
                  </b-table>
                    <b-pagination align="center" v-model="pagination.currentPage" :total-rows="pagination.totalRows"
                      :per-page="pagination.itemsPerPage" class="my-0" @change="changePage">
                    </b-pagination>
                  </div>
              </div>
              <div v-else>
                <p>No clients added, add one on the Client > Ecosystem vendors section</p>
              </div>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-form>

    <edit-services-modal
      :modal-data="editServiceModalData"
      @close="closeEditServiceModal"
      @save-changes="saveChangesEditServiceModal" />

    <div class="vendor-edit__fab-menu d-flex position-fixed">
      <b-button form="vendorForm" type="reset" class="px-3 mr-4">
        Cancel
      </b-button>

      <b-button variant="primary" form="vendorForm" type="submit" class="px-5 position-relative"
        :disabled="!isValidForm || isLoadingUpdate">
        <b-spinner v-if="isLoadingUpdate" class="position-absolute vendor-edit__btn-save-spinner" small />

        {{ isEditing ? 'Save' : 'Create' }}
      </b-button>
    </div>
  </div>
</template>

<script>
import errorHandler from '@/mixins/errorHandler';
import { required } from 'vuelidate/lib/validators';
import pagination from '@/mixins/lists/pagination';
import axios from 'axios';
import EditServicesModal from './EditServicesModal.vue';
import LoadingContent from '../../../components/LoadingContent.vue';

export default {
  name: 'VendorEdit',
  components: {
    EditServicesModal,
    LoadingContent,
  },
  props: {
    id: {
      type: [ String, Number ],
      default: '',
    },
  },
  mixins: [ errorHandler, pagination ],
  data() {
    return {
      errorMessages: {
        required: 'This field is required',
      },
      vendor: {
        is_partner: null,
        name: '',
        services: [],
      },
      rawServices: [],
      services: [],
      vendorClients: [],
      isLoading: false,
      isLoadingUpdate: false,
      isLoadingVendor: false,
      isLoadingClients: false,
      editServiceModalData: null,
      tableFields: [
        { key: 'id', label: 'Client ID' },
        { key: 'name', label: 'Client Name' },
        { key: 'actions', label: 'Actions' },
      ],
    };
  },
  computed: {
    vendorId() {
      return Number(this.id);
    },
    isEditing() {
      return this.$route.path.includes('edit');
    },
    pageTitle() {
      return this.isEditing ? `Edit ${this.vendor.name}` : 'Add new Vendor';
    },
    isValidForm() {
      return !this.$v.vendor.$invalid;
    },
  },
  validations() {
    return {
      vendor: {
        name: { required },
        is_partner: { required },
      },
    };
  },
  methods: {
    getServiceTooltip(id) {
      return this.rawServices.find(service => service.id === id)?.description;
    },
    validateIsArrayAndLength(array) {
      return Array.isArray(array) && array.length;
    },
    isChecked(id) {
      return this.validateIsArrayAndLength(this.vendor.services)
        && this.vendor.services.some(service => service.id === id);
    },
    fetchVendorsServices() {
      return axios.get('/v1/care-ecosystem/services')
        .then(response => {
          this.rawServices = response.data.entries;
          this.services = response.data.entries.map(service => ({
            id: service.id,
            name: service.name,
            description_en: service.description,
            description_es: service?.description_es || null,
            description_pt: service?.description_pt || null,
            description_fr: service?.description_fr || null,
          }));

          if (this.isEditing) {
            this.isLoadingVendor = true;

            const params = { fetch_vendor_services: true, fetch_services: true };

            axios.get(`/v1/care-ecosystem/vendors/${this.vendorId}`, { params })
              .then(responseVendor => {
                this.vendor = responseVendor.data;

                this.vendor.services = this.vendor.vendor_services.map(enabledService => {
                  const vendorService = this.services.find(service => service.id === enabledService.service_id);
                  vendorService.description_en = enabledService.description_en;
                  vendorService.description_pt = enabledService.description_pt;
                  vendorService.description_es = enabledService.description_es;
                  vendorService.description_fr = enabledService.description_fr;

                  return vendorService || enabledService;
                });
              }).catch(err => {
                this.$noty.error(`Failed to fetch care ecosystem vendor: ${err}`);
              }).finally(() => {
                this.isLoadingVendor = false;
              });
          }
        })
        .catch(err => {
          this.$noty.error(`Failed to fetch care ecosystem vendor services: ${err}`);
        })
        .finally(() => {
          this.isLoadingServices = false;
        });
    },
    onPageChange() {
      this.fetchVendorClients();
    },
    fetchVendorClients() {
      if (!this.isEditing) {
        return;
      }

      this.isLoadingClients = true;

      const params = {
        offset: (this.pagination.currentPage - 1) * this.pagination.itemsPerPage,
        limit: this.pagination.itemsPerPage,
      };

      axios.get(`/v1/care-ecosystem/vendors/${this.vendorId}/institutions`, { params })
        .then(response => {
          this.vendorClients = response.data.entries;
          this.pagination.totalRows = response.data.total;
        }).catch(err => {
          this.$noty.error(`Failed to fetch care ecosystem vendor clients: ${err}`);
        }).finally(() => {
          this.isLoadingClients = false;
        });
    },
    discardAndBack() {
      this.resetValues();
      this.$router.push({ name: 'CareEcosystemVendors' });
    },
    editServiceDetails(service) {
      const vendorService = this.vendor.services.find(serviceObj => serviceObj.id === service.id);
      this.editServiceModalData = {
        vendor: this.vendor,
        service: { ...vendorService },
      };
    },
    handleCreate() {
      axios.post('/v1/care-ecosystem/vendors', this.vendor)
        .then(response => {
          if (response) {
            this.$noty.success('Vendor added successfully');

            this.resetValues();
            this.$router.push({ name: 'CareEcosystemVendors' });
          }
        })
        .catch(err => {
          this.$noty.error(`Failed to add care ecosystem vendor: ${err}`);
        })
        .finally(() => {
          this.isLoadingUpdate = false;
        });
    },
    handleEdit() {
      const filteredVendor = {
        name: this.vendor.name,
        is_partner: this.vendor.is_partner,
        services: this.vendor.services.map(({ name, ...rest }) => ({ ...rest })),
      };

      axios.put(`/v1/care-ecosystem/vendors/${this.vendorId}`, filteredVendor)
        .then(response => {
          if (response) {
            this.$noty.success('Vendor updated successfully');

            this.resetValues();
            this.$router.push({ name: 'CareEcosystemVendors' });
          }
        })
        .catch(err => {
          this.$noty.error(`Failed to update care ecosystem vendor: ${err}`);
        })
        .finally(() => {
          this.isLoadingUpdate = false;
        });
    },
    closeEditServiceModal() {
      this.editServiceModalData = null;
    },
    saveChangesEditServiceModal(updatedService) {
      this.vendor.services.map(service => {
        if (service.id === updatedService.id) {
          service.description_en = updatedService.description_en;
          service.description_es = updatedService.description_es;
          service.description_pt = updatedService.description_pt;
          service.description_fr = updatedService.description_fr;
        }

        return service;
      });
    },
    handleSubmit() {
      if (this.isValidForm) {
        this.isLoadingUpdate = true;

        if (this.isEditing) {
          this.handleEdit();
        } else {
          this.handleCreate();
        }
      }
    },
    resetValues() {
      this.vendor = {
        is_partner: null,
        name: '',
        services: [],
      };
      this.editServiceModalData = null;
    },
    handleReset() {
      this.resetValues();
      this.discardAndBack();
    },
  },
  beforeMount() {
    this.isLoading = true;

    this.fetchVendorsServices().then(
      this.fetchVendorClients(),
    ).finally(() => {
      this.isLoading = false;
    });
  },
};
</script>

<style lang="scss">
.vendor-edit {
  &__form-group-name {
    max-width: 480px;
  }

  &__is-partner-tooltip-target {
    top: -2px;
    right: -24px;
  }

  &__fab-menu {
    bottom: 32px;
    right: 32px;
    z-index: 10;
  }

  &__btn-save-spinner {
    left: 16px;
    top: 9px;
  }
}
</style>
