<template>
  <b-modal
    v-if="isVisible"
    size="lg"
    button-size="sm"
    :visible="isVisible"
    centered
    @hidden="close"
    @cancel="close">
    <template #modal-title>
      <h6>{{ modalData.vendor.name }}</h6>

      <h6 class="font-weight-bold mb-0">
        {{ modalData.service.name }}
        <small class="text-muted">default configs</small>
      </h6>
    </template>

    <b-row>
      <b-col>
        <b-form-group
          label-class="p-0"
          :description="DEFAULT_VENDOR_SERVICE_DESCRIPTION"
          :label="modalFields.en.label"
          :state="isValid('modalData.service.description_en')"
          :invalid-feedback="handleError('modalData.service.description_en')">
          <b-form-textarea v-model="modalData.service.description_en"/>
        </b-form-group>

        <b-form-group
          label-class="p-0"
          :description="DEFAULT_VENDOR_SERVICE_DESCRIPTION"
          :label="modalFields.pt.label">
          <b-form-textarea v-model="modalData.service.description_pt"/>
        </b-form-group>

        <b-form-group
          label-class="p-0"
          :description="DEFAULT_VENDOR_SERVICE_DESCRIPTION"
          :label="modalFields.es.label">
          <b-form-textarea v-model="modalData.service.description_es"/>
        </b-form-group>

        <b-form-group
          label-class="p-0"
          :description="DEFAULT_VENDOR_SERVICE_DESCRIPTION"
          :label="modalFields.fr.label">
          <b-form-textarea v-model="modalData.service.description_fr"/>
        </b-form-group>
      </b-col>
    </b-row>

    <template #modal-footer="{ hide }">
      <b-button variant="secondary" @click="hide('cancel')">Cancel</b-button>

      <b-button
        variant="primary"
        @click="saveChanges"
        :disabled="!isValidForm">
        Save
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';
import { DEFAULT_VENDOR_SERVICE_DESCRIPTION } from '@/scripts/constants';

export default {
  name: 'EditServicesModal',
  emits: [ 'change', 'update-service' ],
  props: {
    modalData: Object,
  },
  data() {
    return {
      DEFAULT_VENDOR_SERVICE_DESCRIPTION,
      errorMessages: {
        required: 'This field is required',
      },
    };
  },
  mixins: [ errorHandler ],
  validations() {
    return {
      modalData: {
        service: {
          description_en: { required },
        },
      },
    };
  },
  computed: {
    isValidForm() {
      return !this.$v.modalData.$invalid;
    },
    modalFields() {
      return {
        en: {
          label: `${this.modalData.vendor.name} - ${this.modalData.service.name} - EN`,
        },
        pt: {
          label: `${this.modalData.vendor.name} - ${this.modalData.service.name} - PT`,
        },
        es: {
          label: `${this.modalData.vendor.name} - ${this.modalData.service.name} - ES`,
        },
        fr: {
          label: `${this.modalData.vendor.name} - ${this.modalData.service.name} - FR`,
        },
      };
    },
    isVisible() {
      return !!this.modalData;
    },
  },
  methods: {
    saveChanges() {
      if (this.isValidForm) {
        this.$emit('save-changes', this.modalData.service);
        this.close();
      } else {
        this.$noty.error('Please fill all required fields');
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
